import { Component, AfterViewInit } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../services/_services';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";

declare var $: any;
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {

  public activeUser            : any;
  public activeRoute           : any;
  public routerSubscription    : any;

  public menus :any = [
    // { name: "Dashboard",               route: "/dashboard",                active: false, onlyAdmin: false },
    { name: "Categories",              route: "/categories",               active: false, onlyAdmin: true },
    { name: "Blogs",                   route: "/blogs",                    active: true,  onlyAdmin: false },
    { name: "Users",                   route: "/users",                    active: false, onlyAdmin: true  },
    { name: "Places Content",          route: "/places-content",                    active: false, onlyAdmin: true  },
    // { name: "Appointments",            route: "/appointment-leads",        active: false, onlyAdmin: false },
    // { name: "Visits",                  route: "/visit-leads",              active: false, onlyAdmin: false },
    // { name: "Sales",                   route: "/sale-leads",               active: false, onlyAdmin: false },
    // // { name: "Account Settings",        route: "/account",                  active: false, onlyAdmin: false },
    // { name: "Bin",                     route: "/bin",                      active: false, onlyAdmin: true  }
  ]

  constructor(
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
      this.routerSubscription = this.router.events.subscribe((val) => {
        // Get Current User
        this.activeUser = this.authenticationService.get_currentUserValue();

        if(val instanceof NavigationEnd) {
          this.activeRoute = val.url;

          if(val.url == '/') {
              this.menus[1].active = true;
          } else {
              for (let i = 0; i < this.menus.length; i++) {
                  if(this.menus[i].route == val.url)
                    this.menus[i].active = true;
                  else
                    this.menus[i].active = false;
              }
          }

          // Only admin can manage users
          if((this.activeRoute === '/users' || this.activeRoute === '/bin') && this.activeUser.is_admin == 0)
            this.router.navigate(['/']);
   			}
	    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  showMeanu(menu) {
    if(menu.onlyAdmin) {
      if(this.activeUser.is_admin === 1)
        return true;
      else
        return false;
    } else {
        return true;
    }
  }

  logout() {
    // this.authenticationService.logout().then(resp => {
    //   if(resp)
    //     this.router.navigate(['login']);;
    // });

    this.authenticationService.simpleLogout();
    this.router.navigate(['/login']);
  }
}
