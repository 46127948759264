import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { AppSettings } from './app.setting';
import { BehaviorSubject, Observable, throwError, Subject } from 'rxjs';
import { catchError, retry, finalize, tap, map, takeUntil } from 'rxjs/operators';

declare var config: any;

import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class HelperService {
    public corsHeaders: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    });
    
    public corsHeaders2: any = new HttpHeaders({
      'Content-Type': '',
      'Accept': 'application/json',
    });

    public contentFunction:any = {};

    public searchParms : any;
    public searchesURL  : any = "";

    // Https Params
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    public httpLoading     = false;

    constructor(
      private http: HttpClient,
      private authenticationService: AuthenticationService,
    ) {
        let subscription = this.authenticationService.currentUserS$.subscribe(user => {
          if (user) {
              this.corsHeaders = {
                    headers: new HttpHeaders()
                      .set('Content-Type',  'application/json')
                      .set('Accept',  'application/json')
                      // .set('Authorization',  `Bearer ${user.access_token}`)
                  }
          } else {
              this.corsHeaders = new HttpHeaders({
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json',
                                      });;
          }
        });
    }


    //////////////////////////////////////////
    /********* HTTP Requests Fns ***********/
    ////////////////////////////////////////
    httpGetRequests(url): Promise<any> {
        // Set loader true
        this.httpLoading = true;

        return this.http.get(url, this.corsHeaders)
            .pipe( takeUntil(this.ngUnsubscribe) )
            .toPromise()
            .then( resp => {
                // Set loader false
                this.httpLoading = false;

                return resp;
                // console.log("resp: ",resp);
            })
            .catch(error => {
                // Set loader false
                this.httpLoading = false;
                console.log("helperFunc error: ",error);

                // Show Error Msg
                if(typeof error.error != "undefined") {
                    if(error.error.message == "Unauthenticated.") {
                      this.authenticationService.logout();
                    }

                    throw error;
                } else {
                    throw "Something went wrong. Please try again.";
                }
            });
      }

      httpPostRequests(url, data): Promise<any> {
          // Set loader true
          this.httpLoading = true;

          return this.http.post(url, data, this.corsHeaders)
              .pipe( takeUntil(this.ngUnsubscribe) )
              .toPromise()
              .then( resp => {
                  // Set loader false
                  this.httpLoading = false;
                  return resp;
              })
              .catch(error => {
                  // Set loader false
                  this.httpLoading = false;
                  console.log("error: ",error);

                  // Show Error Msg
                  if(typeof error.error != "undefined") {
                      if(error.error.message == "Unauthenticated.") {
                        this.authenticationService.logout();
                      }

                      throw error;
                  } else {
                      throw "Something went wrong. Please try again.";
                  }
              });
        }
      
        httpPostRequestsMulti(url, data): Promise<any> {
          // Set loader true
          this.httpLoading = true;

          return this.http.post(url, data, this.corsHeaders2)
              .pipe( takeUntil(this.ngUnsubscribe) )
              .toPromise()
              .then( resp => {
                  // Set loader false
                  this.httpLoading = false;
                  return resp;
              })
              .catch(error => {
                  // Set loader false
                  this.httpLoading = false;
                  console.log("error: ",error);

                  // Show Error Msg
                  if(typeof error.error != "undefined") {
                      if(error.error.message == "Unauthenticated.") {
                        this.authenticationService.logout();
                      }

                      throw error;
                  } else {
                      throw "Something went wrong. Please try again.";
                  }
              });
        }

      httpDeleteRequests(url): Promise<any> {
          // Set loader true
          this.httpLoading = true;

          return this.http.delete(url, this.corsHeaders)
              .pipe( takeUntil(this.ngUnsubscribe) )
              .toPromise()
              .then( resp => {
                  // Set loader false
                  this.httpLoading = false;

                  console.log("resp: ",resp);
                  return resp;
              })
              .catch(error => {
                  // Set loader false
                  this.httpLoading = false;

                  console.log("error: ",error);

                  // Show Error Msg
                  if(typeof error.error != "undefined") {
                      if(error.error.message == "Unauthenticated.") {
                        this.authenticationService.logout();
                      }

                      throw error;
                  } else {
                      throw "Something went wrong. Please try again.";
                  }
              });
        }

        public getSearchParms() {
          return this.searchParms;
        }

        public setSearchParms(params) {
          if(typeof localStorage != "undefined") {
            localStorage.setItem('searchParms', JSON.stringify(params));
            this.searchParms = params;
          }
        }

        updateSearch_URL(url) {
          this.searchesURL = url;

          // Set to local storage
          if (typeof localStorage != 'undefined')
            localStorage.setItem('Search_URL', JSON.stringify(this.searchesURL));
        }

        renderLocURL(slug) {
          slug = slug.split("-");
          return { id: slug[slug.length - 2], name: slug[0], searchBy: slug[slug.length - 1] };
        }

        public getIPAddress(): Promise<any> {
      // return this.http.get("");

      // Set loader true
      this.httpLoading = true;

      return this.http.get('https://api.ipify.org/?format=json')
          .pipe( takeUntil(this.ngUnsubscribe) )
          .toPromise()
          .then( resp => {
              // Set loader false
              this.httpLoading = false;

              return resp;
              // console.log("resp: ",resp);
          })
          .catch(error => {
              // Set loader false
              this.httpLoading = false;
              console.log("helperFunc error: ",error);

              // Show Error Msg
              if(typeof error.error != "undefined") {
                  if(error.error.message == "Unauthenticated.") {
                    this.authenticationService.logout();
                  }

                  throw error;
              } else {
                  throw "Something went wrong. Please try again.";
              }
          });
    }

    getParentLocations(id): Promise<any> {
      const url = AppSettings.API_ENDPOINT + "locations/get-parent-locations-by-sublocation?sub_loc=" + id;
      return this.httpGetRequests(url).then(resp => {

        if (typeof resp.p_loc != "undefined") {
            let searchedArea = resp.p_loc.slice()[0];

            let parentLocs  = [...resp.p_loc.slice().reverse()];

            // Remove the Last parent as its the same as searched area
            parentLocs.pop();

            return {searchedArea: searchedArea, parentLocs: parentLocs};
        }
      }).catch(error => {
          console.log("error: ",error);
      })
    }

    checkValidCity(areaTypes, cityName) {
      if(areaTypes.indexOf('locality') != -1 || areaTypes.indexOf("administrative_area_level_3") != -1 || areaTypes.indexOf("administrative_area_level_2") != -1) {
        if (AppSettings.cities.filter(e => e.name === cityName).length > 0) {
          return true;
        }
      }
      return false;
    }

    /**
    * Added By Kareem
    */

    getContentFunc(){
      return JSON.parse(localStorage.getItem('contentFunc'));
    }

    setContentFunc(contentFunc){
      localStorage.setItem('contentFunc', JSON.stringify(contentFunc));
    }


}
