import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService, SEOService, AppSettings, HelperService } from '../../services/_services';
import { Location } from '@angular/common';

import { Router, Route, ActivatedRoute, NavigationEnd } from "@angular/router";
// import { SiginUpComponent } from '../../entry-components/sigin-up/sigin-up.component';

// Sweet Alerts
import Swal from 'sweetalert2'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public isUserLoggedIn     : any = false;
  public loading            : any = false;
  public toggleSide         : any = false;
  public sideNav            : any = false;
  public user :any = {
    'first_name'            : '',
    'last_name'             : '',
    'email'                 : '',
    'password'              : '',
    'phone_number'          : '',
    'dob'                   : null,
  }
  public searchByID         : any = "";
  public searchingLoad      : any = false;
  public atHomePage         : any = false;
  public propuseSub         : any;
  public Purpose            : any;

  public activeUser            : any;
  public activeRoute           : any;
  public routerSubscription    : any;

  public menus :any = [
    // { name: "Dashboard",               route: "/dashboard",                active: false, onlyAdmin: false },
    { name: "Categories",              route: "/categories",               active: false, onlyAdmin: true },
    { name: "Blogs",                   route: "/blogs",                    active: true,  onlyAdmin: false },
    { name: "Users",                   route: "/users",                    active: false, onlyAdmin: true  },
    // { name: "Places Content",          route: "/places-content",           active: false, onlyAdmin: false },
    // { name: "Appointments",            route: "/appointment-leads",        active: false, onlyAdmin: false },
    // { name: "Visits",                  route: "/visit-leads",              active: false, onlyAdmin: false },
    // { name: "Sales",                   route: "/sale-leads",               active: false, onlyAdmin: false },
    // // { name: "Account Settings",        route: "/account",                  active: false, onlyAdmin: false },
    // { name: "Bin",                     route: "/bin",                      active: false, onlyAdmin: true  }
  ]

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private seoService: SEOService,
    private helperService: HelperService,
    private _location: Location,
  ) {
      // // Check for Dashboard to set Navbar Css Class
      // this.router.events.subscribe((val) => {
      //   if(val instanceof NavigationEnd) {
      //     if(val.url == '/') {
      //         this.atHomePage = true;
      //     } else {
      //         this.atHomePage = false;
      //     }
      //   }
      // });
      this.routerSubscription = this.router.events.subscribe((val) => {
        // Get Current User
        this.activeUser = this.authenticationService.get_currentUserValue();

        if(val instanceof NavigationEnd) {
          this.activeRoute = val.url;

          if(val.url == '/') {
              this.menus[0].active = true;
          } else {
              for (let i = 0; i < this.menus.length; i++) {
                  if(this.menus[i].route == val.url)
                    this.menus[i].active = true;
                  else
                    this.menus[i].active = false;
              }
          }

          // Only admin can manage users
          if((this.activeRoute === '/users' || this.activeRoute === '/bin') && this.activeUser.role !== 'Admin')
            this.router.navigate(['/']);
   			}
	    });
  }

  ngOnInit() {
    // Subscribe To User Local Login
    this.authenticationService.currentUserS$.subscribe(user => {
      if (user) {
          this.user = user;
          // this.user = user;
          this.isUserLoggedIn = true;
          // console.log("this.user: ",this.user);
      } else {
          // // Move user to homepage
          // let self = this;
          // setTimeout(function() {
          //   self.isUserLoggedIn = false;
          //   console.log("User LoggedOut");
          //   if (self.activatedRoute.snapshot.routeConfig.path.indexOf('dashboard') !== -1) {
          //       self.router.navigate(['/']);
          //   }
          // }, 500)
      }
    });
  }

  ngOnDestroy() {
    // if (typeof this.propuseSub != 'undefined') {
    //   //prevent memory leak when component destroyed
    //   this.propuseSub.unsubscribe();
    // }

    this.routerSubscription.unsubscribe();
  }
  showMeanu(menu) {
    if(menu.onlyAdmin) {
      if(this.activeUser.role === 'Admin')
        return true;
      else
        return false;
    } else {
        return true;
    }
  }
  logout() {
    // this.authenticationService.logout().then(resp => {
    //   if(resp)
    //     this.router.navigate(['login']);;
    // });

    this.authenticationService.simpleLogout();
    this.router.navigate(['/login']);
  }
  toggleSidebar() {
    this.toggleSide = !this.toggleSide;
    this.sideNav = !this.sideNav;
    $('body').toggleClass("overflow-hidden");
  }
  closeSideNav(){
    this.toggleSide = false;
    this.sideNav = false;
    $('body').removeClass("overflow-hidden");
  }
}
